import { applySnapshot, flow, Instance, types } from "mobx-state-tree";
import { api } from "../service/api";
import { ContextModel, SearchModel } from "../models/Common";
import { ActiveProgramsModel, MyClientsModel, RowsModel } from "../models/MyClients";
import { IClientCSVData, IMyClientsParams, IMyClientsSearchParams } from "../constants/interfaces";
import { serviceCoach } from "../utils/env";
import { formatDate } from "../utils/date";

const MyClientsStore = types
  .model({
    items: types.array(MyClientsModel),
    rows: types.optional(types.array(RowsModel), []),
    context: types.maybe(ContextModel),
    isLoading: types.optional(types.boolean, false),
    activePrograms: types.optional(types.array(ActiveProgramsModel), []),
    search: types.optional(types.array(SearchModel), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    buildUrl(params: IMyClientsParams, url: string) {
      const urlDefault = `${serviceCoach}${url}`;
      const queryParams = new URLSearchParams();

      Object.keys(params).forEach((key) => {
        const value = params[key as keyof IMyClientsParams];
        if (value !== undefined && value !== null) {
          queryParams.append(key, value.toString());
        }
      });

      return `${urlDefault}?${queryParams.toString()}`;
    },
  }))
  .actions((self) => ({
    fetch: flow(function* (params: IMyClientsParams) {
      try {
        const url = self.buildUrl(params, "/api/v1/my-clients");
        const { data } = yield api.get(url);
        const { context, items } = data;

        let rowsAux: Instance<typeof RowsModel>[] | [] = [];
        self.isLoading = true;

        if (items?.length && items.length > 0) {
          items?.forEach((item: Instance<typeof MyClientsModel>, index: number) => {
            let row: Instance<typeof RowsModel> = {
              id: 0,
              fullName: "-",
              activeProgram: "-",
              programStartDate: "-",
              lastLoginDate: "-",
              loginsPast30Days: 0,
              status: "-",
              redirect: 0,
            };

            row.id = item.id;
            row.fullName = item.fullName;
            row.activeProgram = item.activeProgram;
            row.programStartDate = item?.programStartDate ? formatDate(item.programStartDate) : "-";
            row.lastLoginDate = item.lastLoginDate ? item?.lastLoginDate : "-";
            row.loginsPast30Days = item.loginsPast30Days ? item?.loginsPast30Days : 0;
            row.status = item?.status ?? "-";
            row.redirect = item.id;

            rowsAux = [...rowsAux, row];
          });
        }

        applySnapshot(self, {
          ...self,
          items,
          rows: rowsAux,
          context,
          isLoading: false,
        });
      } catch (error) {
        console.log("Error fetch clients table:", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    fetchCSVData: flow(function* (params: IMyClientsParams) {
      try {
        const url = self.buildUrl(params, "/api/v1/my-clients");
        const { data } = yield api.get(url);
        const { items } = data;

        let csvData: IClientCSVData[] = [];
        self.isLoading = true;
        //TODO: At the client's request, the 'status' value is temporarily removed. The task is FF-206
        if (items?.length && items.length > 0) {
          items?.forEach((item: IClientCSVData) => {
            let row: IClientCSVData = {
              fullName: "-",
              activeProgram: "-",
              programStartDate: "-",
              lastLoginDate: "-",
              loginsPast30Days: "-",
              // status: "-",
              phone: "-",
              dateOfBirth: "-",
              email: "-",
            };

            row.fullName = item?.fullName;
            row.activeProgram = item?.activeProgram;
            row.programStartDate = item?.programStartDate ? formatDate(item.programStartDate) : "-";
            row.lastLoginDate = item?.lastLoginDate ? item?.lastLoginDate : "-";
            row.loginsPast30Days = item.loginsPast30Days ? item?.loginsPast30Days.toString() : "0";
            // row.status = item?.status ?? "-";
            row.phone = item?.phone ?? "-";
            row.dateOfBirth = item?.dateOfBirth ? formatDate(item?.dateOfBirth) : "-";
            row.email = item?.email ?? "-";

            csvData = [...csvData, row];
          });
        }
        return csvData;
      } catch (error) {
        console.log("Error fetchCSVData:", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    fetchActivePrograms: flow(function* (params: IMyClientsParams) {
      try {
        self.isLoading = true;
        const url = self.buildUrl(params, "/api/v1/active-programs");
        const { data } = yield api.get(url);

        applySnapshot(self, {
          ...self,
          activePrograms: data,
        });
      } catch (error) {
        console.log("Error active programs:", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    fetchSearch: flow(function* (params: IMyClientsSearchParams) {
      try {
        self.isLoading = true;
        const url = self.buildUrl(params, "/api/v1/my-clients/search");
        const { data } = yield api.get(url);

        applySnapshot(self, {
          ...self,
          search: data,
        });
      } catch (error) {
        console.log("Error my clients search:", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    clear: function () {
      applySnapshot(self, {
        ...self,
        items: [],
        rows: [],
        context: undefined,
      });
    },
  }));

export const ClientsStore = MyClientsStore.create();
