import { useEffect, useState } from "react";
import Card, { CardColor } from "../../components/Card/Card";
import { useNavigate } from "react-router-dom";
import MainCard from "../../components/MainCard/MainCard";
import { colors } from "../../constants/colors";
import "./styles.scss";
import { homeStore } from "../../store/HomeStore";
import Spinner from "../../components/Spinner/Spinner";
import { ROUTE_PATHS } from "../../constants/routePaths";
import { Box, Link, SvgIconTypeMap, Typography } from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SsidChartOutlined from "@mui/icons-material/SsidChartOutlined";
import AttachMoneyOutlined from "@mui/icons-material/AttachMoneyOutlined";
import ImportantDevicesOutlined from "@mui/icons-material/ImportantDevicesOutlined";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface Card {
  title: string;
  primaryText?: string | number;
  secondPrimaryText?: string | number;
  secondaryText: string;
  secondSecondaryText?: string;
  path: string;
  color: CardColor;
  id: string;
  customClick?: boolean;
  icon: OverridableComponent<SvgIconTypeMap>;
}

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  let navigate = useNavigate();

  const handleClick = (path: string, clientType: string) => {
    navigate(path, { state: { clientType: clientType } });
  };

  const cardsData: Card[] = [
    {
      title: "My Leads",
      primaryText: homeStore.coach?.countLeads || "0",
      secondaryText: "This Month",
      path: ROUTE_PATHS.MY_LEADS,
      color: "primary",
      id: "home-card-my-leads",
      customClick: true,
      icon: FilterListOutlinedIcon,
    },
    {
      title: "My Clients",
      primaryText: homeStore.coach?.countClients || "0",
      secondPrimaryText: homeStore.coach?.countClientsVIP || "0",
      secondaryText: "This Month",
      secondSecondaryText: "Total VIP",
      path: ROUTE_PATHS.MY_CLIENTS,
      color: "primary",
      id: "home-card-my-clients",
      icon: PeopleAltOutlinedIcon,
    },
    {
      title: isMobile ? "Training" : "Training Portal",
      secondaryText: "Program Information",
      path: ROUTE_PATHS.TRAINING_PORTAL,
      color: "secondary",
      id: "home-card-training-portal",
      icon: ImportantDevicesOutlined,
    },
    {
      title: "Back Office",
      secondaryText: "Commissions & Payments",
      path: ROUTE_PATHS.PAP,
      color: "secondary",
      id: "home-card-pap",
      icon: AttachMoneyOutlined,
    },
    {
      title: "Energy Chart",
      secondaryText: "Marketing Strategy",
      path: ROUTE_PATHS.ENERGY_CHART,
      color: "secondary",
      id: "home-card-energy-chart",
      icon: SsidChartOutlined,
    },
  ];

  useEffect(() => {
    const fetchHome = async () => {
      await homeStore.fetchHome();
      setLoading(false);
    };

    fetchHome();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <MainCard backgroundColor={colors.background.main} minHeight="800px">
      {loading ? (
        <Spinner size={50} sx={{ position: "absolute", top: "45%", left: "55%" }} />
      ) : (
        <div className="home">
          {cardsData.map((card, index) => (
            <Card
              className="card"
              id={card.id}
              key={index}
              title={card.title}
              primaryText={card.primaryText}
              secondPrimaryText={card.secondPrimaryText}
              secondaryText={card.secondaryText}
              secondSecondaryText={card.secondSecondaryText}
              onClick={(clientType) => handleClick(card.path, clientType)}
              color={card.color}
              customClick={card.customClick}
              icon={card.icon}
            />
          ))}
        </div>
      )}

      <Box sx={{ mt: isMobile ? 4 : "auto", textAlign: "center" }}>
        <Typography variant="body1" sx={{ color: "#333" }}>
          Have new ideas or suggestions for Coach Connect? Email{" "}
          <Link href="mailto:cc_feedback@fasterwaytofatloss.com" sx={{ color: colors.blue.two, fontWeight: "bold" }}>
            cc_feedback@fasterwaytofatloss.com
          </Link>
        </Typography>
      </Box>
    </MainCard>
  );
};

export default Home;
